import React from "react"
import * as componentStyle from "./loadingring.module.css"

const LoadingRing = ({ active, size, style }) => {
  return (
    <div className={componentStyle.container} style={{ width: active ? size : 0, height: active ? size : 0, opacity: active ? 1 : 0, ...style, margin: active ? null : "0 !important" }}>
      <svg className="loadingCircle" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M40 20C40 14.6957 37.8929 9.60859 34.1421 5.85786C30.3914 2.10714 25.3043 4.00466e-07 20 0C14.6957 -4.00466e-07 9.60859 2.10714 5.85787 5.85786C2.10714 9.60859 8.00932e-07 14.6957 0 20L3.11946 20C3.11946 15.523 4.89794 11.2294 8.06366 8.06365C11.2294 4.89794 15.523 3.11946 20 3.11946C24.477 3.11946 28.7706 4.89794 31.9363 8.06366C35.1021 11.2294 36.8805 15.523 36.8805 20H40Z"
          fill="#FFF"
        />
        <path
          d="M40 20C40 22.6264 39.4827 25.2272 38.4776 27.6537C37.4725 30.0802 35.9993 32.285 34.1421 34.1421C32.285 35.9993 30.0802 37.4725 27.6537 38.4776C25.2272 39.4827 22.6264 40 20 40C17.3736 40 14.7728 39.4827 12.3463 38.4776C9.91982 37.4725 7.71504 35.9993 5.85786 34.1421C4.00069 32.285 2.5275 30.0802 1.52241 27.6537C0.517315 25.2272 -2.2961e-07 22.6264 0 20L3.13268 20C3.13268 22.215 3.56897 24.4084 4.41663 26.4548C5.26429 28.5013 6.50673 30.3607 8.073 31.927C9.63928 33.4933 11.4987 34.7357 13.5452 35.5834C15.5916 36.431 17.785 36.8673 20 36.8673C22.215 36.8673 24.4084 36.431 26.4548 35.5834C28.5013 34.7357 30.3607 33.4933 31.927 31.927C33.4933 30.3607 34.7357 28.5013 35.5834 26.4548C36.431 24.4084 36.8673 22.215 36.8673 20H40Z"
          fill="#0000"
        />
      </svg>
    </div>
  )
}

export default LoadingRing

// style={{ width: size, height: size }}
